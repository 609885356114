import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

import { DEFAULT_REACT_QUERY_CACHE_TIME } from 'Config/reactQueryConfig';
import { triggerVehicleListFetchedEvent, triggerVehicleListSortLoadedEvent } from 'Services/analytics/events';
import { logger } from 'Services/logger/logger';
import {
	useAdditionalVehicleDetails,
	useSetAdditionalVehicleDetails,
} from 'Stores/AdditionalVehicleDetailsStore/AdditionalVehicleDetailsStore';
import { useSetTimeId } from 'Stores/BidCountSortStore/BidCountSortStore';
import { useAppliedFiltersCount } from 'Stores/FilterStore/VehicleListFilter/VehicleListFilterStore';
import { useListType } from 'Stores/ListStore/ListStore';
import { LIST_POSITION_PAGES } from 'Stores/VehicleListPositionStore/VehicleListPositionsStore.consts';
import { useGetListPosition } from 'Stores/VehicleListPositionStore/VehicleListPositionStore';
import { LIST_TYPES } from 'Utilities/consts';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useSaleTimes from 'Utilities/hooks/useSaleTimes';

import useShouldFetchVehicles from '../useShouldFetchVehicles';
import useSortOrder from '../useSortOrder/useSortOrder';
import { useClearVehicleListCache } from '../useVehicleListCache/useClearVehicleListCache';
import type { UseVehicleListQuery } from '../useVehicleListQuery/useVehicleListQuery';

import {
	checkVehiclesAreFetchedDuringSale,
	getLatestVehicleEnquiryIds,
	getLoadedVehiclesCount,
	mapVehicleListWithAdditionalDetails,
	vehicleListFetcher,
} from './useFetchVehicleList.helpers';
import type { VehicleListFetcher } from './useFetchVehicleList.types';

export const VEHICLE_LIST_QUERY_KEY = 'vehicle_list';

interface UseFetchVehicleListProps {
	query: UseVehicleListQuery;
}

const useFetchVehicleList = ({ query }: UseFetchVehicleListProps): UseInfiniteQueryResult<VehicleListFetcher> => {
	const getListPosition = useGetListPosition();
	const listPosition = getListPosition(LIST_POSITION_PAGES.marketplace);
	const setTimeId = useSetTimeId();
	const { clearInactiveVehicleListCache } = useClearVehicleListCache();
	const appliedFilterCount = useAppliedFiltersCount();
	const { isSaleActive } = useSaleTimes();
	const shouldFetchVehicles = useShouldFetchVehicles();
	const listType = useListType();
	const { sort } = useSortOrder();
	const isAdditionalVehicleDetailsEnabled = useFeatureToggle(FEATURES.additionalVehicleDetails);
	const additionalVehicleDetails = useAdditionalVehicleDetails();
	const setAdditionalVehicleDetails = useSetAdditionalVehicleDetails();

	return useInfiniteQuery({
		cacheTime: DEFAULT_REACT_QUERY_CACHE_TIME,
		enabled: shouldFetchVehicles,
		getNextPageParam: (lastPage: VehicleListFetcher, allPages: VehicleListFetcher[]) => {
			const loadedCount = getLoadedVehiclesCount(allPages);
			const { nextPage } = lastPage;
			// When returning undefined it makes `hasNextPage` false
			return nextPage ? { loadedCount, nextPage } : undefined;
		},
		onError: (error: AxiosError) => {
			const message = 'There was an issue with fetching vehicle list';
			logger.error({
				context: { extra: { info: message } },
				error,
				message,
				scope: 'useFetchVehicleList',
			});
		},
		onSuccess: (vehicleData) => {
			if (listType !== LIST_TYPES.shortlist) {
				const paginatedListOfIds = getLatestVehicleEnquiryIds(vehicleData);
				triggerVehicleListFetchedEvent(paginatedListOfIds);
			}
			if (vehicleData?.pages?.length === 1) {
				triggerVehicleListSortLoadedEvent(sort);
			}

			clearInactiveVehicleListCache();
			checkVehiclesAreFetchedDuringSale({
				appliedFilterCount,
				isSaleActive: Boolean(isSaleActive),
				query,
				vehicleCount: vehicleData?.pages?.[0].data?.totalCount,
			});

			const timeId = vehicleData?.pages?.[0].data?.timeId;
			if (timeId) {
				setTimeId(timeId);
			}
		},
		queryFn: async (params) => {
			const vehicleList = await vehicleListFetcher({
				...params,
				isUsingCachedData: Boolean(listPosition),
			});

			return isAdditionalVehicleDetailsEnabled
				? mapVehicleListWithAdditionalDetails({
						additionalVehicleDetails,
						listType,
						query,
						setAdditionalVehicleDetails,
						vehicleList,
				  })
				: vehicleList;
		},
		queryKey: [VEHICLE_LIST_QUERY_KEY, query] as [string, UseVehicleListQuery],
		refetchOnWindowFocus: false,
	});
};

export default useFetchVehicleList;
