import type { AxiosRequestConfig } from 'axios';
import type { NextPageContext } from 'next';

import { doesUserHavePaymentsSessionWhenIsomorphicApiServerCall } from 'Auth/paymentsSessionHelper';
import type { OtpAction } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import type { OTPSource } from 'Components/pages/VehiclePayment/components/OTPContent/useOTP.helper';
import type {
	FinanceStatusResponse,
	LatestPostSaleOffer,
	Payment,
	PaymentProcessingResponse,
} from 'Components/pages/VehiclePayment/VehiclePayment.types';
import type { ModalPaidInOutData } from 'Components/pages/Wallet/components/WalletListRow/WalletListRow.helper';
import type {
	CheckFundsApiResponse,
	GetTransactionsApiRequestPayload,
	PaymentAccounts,
	PureTransaction,
	TransactionMeta,
} from 'Components/pages/Wallet/Wallet.const';
import { findDealerToMotorwayTransfer } from 'Server/api/mappers/payment';
import axios from 'Services/axios';
import type { ApiResponseForJsend } from 'Services/motorway/apiClient.types';
import { resolveParams } from 'Services/services.helpers';
import isomorphicApi from 'Utilities/helpers/isomorphicApi';
import { API_ROUTES } from 'Utilities/routes';

type GetLatestPostSaleOfferFx = (
	params: {
		enquiryId: number | string;
		signal?: AbortSignal;
	},
	ctx?: NextPageContext,
) => Promise<LatestPostSaleOffer>;

type ProcessPaymentFx = (payload: UnknownShapeObject) => Promise<PaymentProcessingResponse>;

type CreatePostSaleOfferFx = (payload: UnknownShapeObject, ctx?: NextPageContext) => Promise<LatestPostSaleOffer>;

type GetPaymentFx = (
	params: {
		enquiryId: number | string;
		signal?: AbortSignal;
	},
	ctx?: NextPageContext,
) => Promise<Payment>;

type GenerateOTPFx = (params: {
	enquiryId?: number | string;
	logs?: UnknownShapeObject;
	otpAction?: OtpAction;
	source: OTPSource;
}) => Promise<ApiResponseForJsend>;

type VerifyOTPFx = (params: {
	logs?: UnknownShapeObject;
	source: OTPSource;
	verificationCode: string;
}) => Promise<ApiResponseForJsend>;

type IsUserOTPAuthorisedFx = (
	payload?: unknown,
	ctx?: NextPageContext,
) => Promise<{
	status: number;
}>;

type GetTransactionsFx = (
	payload: GetTransactionsApiRequestPayload,
	ctx?: NextPageContext,
) => Promise<{
	data: PureTransaction[];
	meta: TransactionMeta;
}>;

type GetSingleTransactionFx = (id: number | string, ctx?: NextPageContext) => Promise<ModalPaidInOutData>;

type GetPaymentAccountsFx = (
	payload: UnknownShapeObject,
	ctx?: NextPageContext,
) => Promise<PaymentAccounts | PaymentAccounts[]>;

type CheckFundsFx = (payload: UnknownShapeObject, options?: AxiosRequestConfig) => Promise<CheckFundsApiResponse>;

type RequestCallbackFx = (payload: { name: string; phone: string }) => Promise<ApiResponseForJsend>;

type getFinanceStatusFx = (
	payload: {
		enquiryId: number | string;
		signal?: AbortSignal;
	},
	ctx?: NextPageContext,
) => Promise<ApiResponseForJsend<FinanceStatusResponse>>;

export const getLatestPostSaleOffer: GetLatestPostSaleOfferFx = async ({ enquiryId, signal = undefined }, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/postSaleOffers/latest${resolveParams({ enquiryId })}`, {
				signal,
			});
			return res.data;
		},
		server: async (api) => {
			const res = await api.getLatestPostSaleOffer(enquiryId);
			return res.data;
		},
	});

// eslint-disable-next-line local-rules/function-naming
export const processPayment: ProcessPaymentFx = async (payload) => {
	const res = await axios.put(`${API_ROUTES.PAYMENTS}/process`, payload);
	return res.data;
};

export const createPostSaleOffer: CreatePostSaleOfferFx = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.post(`${API_ROUTES.PAYMENTS}/postSaleOffers/create`, payload);
			return res.data;
		},
		server: async (api) => {
			const res = await api.createPostSaleOffer(payload);
			return res.data;
		},
	});

export const getPayment: GetPaymentFx = async ({ enquiryId, signal = undefined }, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/payments${resolveParams({ enquiryId })}`, { signal });
			return res.data;
		},
		server: async (api) => {
			const res = await api.getPayment(enquiryId);

			return findDealerToMotorwayTransfer(res.data);
		},
	});

export const generateOTP: GenerateOTPFx = async ({ enquiryId, logs, otpAction, source }) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/generate`, { enquiryId, logs, otpAction, source });
	return res.data;
};

// eslint-disable-next-line local-rules/function-naming
export const verifyOTP: VerifyOTPFx = async ({ logs, source, verificationCode }) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/verify`, { logs, source, verificationCode });
	return res.data;
};

export const isUserOTPAuthorised: IsUserOTPAuthorisedFx = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.post(`${API_ROUTES.PAYMENTS}/otp/is-user-authorised`);
			return res.data;
		},
		server: async () => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			return { status: 200 };
		},
	});

export const getTransactions: GetTransactionsFx = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/transactions${resolveParams(payload)}`);
			return res;
		},
		server: async (api) => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			const res = await api.getTransactions(payload);
			return res;
		},
	});

export const getSingleTransaction: GetSingleTransactionFx = async (id, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/transactions/${id}`);
			return res.data;
		},
		server: async (api) => {
			const res = await api.getSingleTransaction(id);
			return res;
		},
	});

export const getPaymentAccounts: GetPaymentAccountsFx = async (payload, ctx) =>
	isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/paymentAccounts${resolveParams(payload)}`);
			return res.data;
		},
		server: async (api) => {
			await doesUserHavePaymentsSessionWhenIsomorphicApiServerCall(ctx);
			const res = await api.getPaymentAccounts(payload);
			return res.data[0];
		},
	});

export const checkFunds: CheckFundsFx = async (payload, options) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/checkFunds`, payload, options);
	return res.data;
};

// eslint-disable-next-line local-rules/function-naming
export const requestCallback: RequestCallbackFx = async (payload) => {
	const res = await axios.post(`${API_ROUTES.PAYMENTS}/request-callback`, payload);
	return res.data;
};

export const getFinanceStatus: getFinanceStatusFx = async (props, ctx) => {
	const { signal, ...payload } = props;

	return isomorphicApi(ctx, {
		browser: async () => {
			const res = await axios.get(`${API_ROUTES.PAYMENTS}/finance-status${resolveParams(payload)}`, { signal });
			return res;
		},
		server: async (api) => api.getFinanceStatus({ enquiryId: payload.enquiryId }),
	});
};
