/* eslint-disable local-rules/function-naming */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { generatePath } from 'Components/shared/Wizard/Router.helpers';
import { logger } from 'Services/logger/logger';
import type { PaymentProviderKey } from 'Services/motorway/services/paymentOptions/paymentOptions.types';
import type { GetPaymentStatusByReferenceResponse } from 'Services/motorway/services/paymentsProcessing/paymentProcessing.types';
import useFeatureToggle from 'Utilities/hooks/useFeatureToggle';
import ROUTES from 'Utilities/routes';

import { usePaymentsWizard } from './helpers/usePaymentsWizard';
import { PAYMENT_OPTION_URL_SLUG_BY_KEY } from './VehiclePayment.const';
import type {
	LatestPostSaleOffer,
	Payment,
	PaymentInitialStateReturn,
	PriceBreakdown,
	VehiclePaymentAvailableRoutes,
	VehiclePaymentPageProps,
	VehiclePaymentRoutePathPrimary,
	VehiclePaymentRoutePathSecondary,
	VehiclePaymentRoutePrefix,
} from './VehiclePayment.types';

const ENTRY_VIEW_RETURN: PaymentInitialStateReturn = {
	journey: 'entry',
};

const PROCESSING_VIEW_RETURN: PaymentInitialStateReturn = {
	journey: 'green',
	offerApproved: true,
};

const SUCCESS_VIEW_RETURN: PaymentInitialStateReturn = {
	journey: 'approved',
	offerApproved: true,
};

const ERROR_VIEW_RETURN: PaymentInitialStateReturn = {
	hasError: true,
	journey: 'error',
};

export const PHONES = {
	ADD_FUNDS: '020 4578 0846',
	DISCOVERY_PAGE: '020 4578 0846',
	GENERAL_PAYMENTS_ERROR: '020 4578 0846',
	INCORRECT_FIGURES: '020 4578 0846',
	NEGATIVE_EQUITY: '020 4578 0846',
	NEXTGEAR_SUPPORT: '0343 50 60 600',
	OTP_SETUP: '020 3885 6242',
	PAYMENT_ERROR: '020 4578 0846',
	PRICING_DETAILS: '020 4578 0846',
	REVISED_OFFER: '020 3885 7079',
	WALLET_ERROR: '020 4578 0846',
	WITHDRAW_FUNDS: '020 4578 0846',
};

export const SUPPORT_EMAILS = {
	PAYMENTS: 'payments@motorway.co.uk',
};

export const resolveApprovedLatestOfferInitialState = (
	payment?: Payment,
	paymentDetails?: GetPaymentStatusByReferenceResponse,
): PaymentInitialStateReturn => {
	// TODO: using [OLD WORLD] `payment`. Needs rethink.
	if (!paymentDetails?.paymentMethod || paymentDetails?.paymentMethod === 'wallet-modulr') {
		switch (payment?.status) {
			case 'done': {
				return SUCCESS_VIEW_RETURN;
			}
			case 'failed':
			case 'errored': {
				return ERROR_VIEW_RETURN;
			}
			default: {
				return PROCESSING_VIEW_RETURN;
			}
		}
	} else {
		switch (paymentDetails?.paymentStatus) {
			case 'done': {
				return SUCCESS_VIEW_RETURN;
			}
			case 'failed': {
				return PROCESSING_VIEW_RETURN;
			}
			default: {
				return PROCESSING_VIEW_RETURN;
			}
		}
	}
};

// eslint-disable-next-line max-len
export const resolvePendingLatestOfferInitialState = (latestOffer?: LatestPostSaleOffer): PaymentInitialStateReturn => {
	switch (latestOffer?.type) {
		case 'closing_bid': {
			return ENTRY_VIEW_RETURN;
		}
		case 'counter_offer': {
			if (latestOffer?.userType === 'agent') {
				if ('dealer' in latestOffer.approvals) {
					return PROCESSING_VIEW_RETURN;
				}

				return ENTRY_VIEW_RETURN;
			}

			return PROCESSING_VIEW_RETURN;
		}
		default: {
			return ENTRY_VIEW_RETURN;
		}
	}
};

// Based on: https://www.notion.so/motorway/Doc-What-should-the-user-see-during-payment-process-66693dcbb6ef4f079e7776d5494cf538
export const resolveVehiclePaymentInitialState = ({
	latestOffer,
	payment,
	paymentDetails,
}: Pick<VehiclePaymentPageProps, 'payment' | 'latestOffer' | 'paymentDetails'>): PaymentInitialStateReturn => {
	switch (latestOffer?.state) {
		case 'pending': {
			return resolvePendingLatestOfferInitialState(latestOffer);
		}
		case 'approved': {
			return resolveApprovedLatestOfferInitialState(payment, paymentDetails);
		}
		case 'rejected': {
			return ERROR_VIEW_RETURN;
		}
		default: {
			return ENTRY_VIEW_RETURN;
		}
	}
};

// TODO: using [OLD WORLD] `payment`. Needs rethink.
export const shouldDisplayNegativeEquityPage = ({
	hasNegativeEquity,
	payment,
}: Pick<VehiclePaymentPageProps, 'hasNegativeEquity' | 'payment'>): boolean => {
	const hasSuccessManualPayment = payment && payment.status === 'done';
	return Boolean(hasNegativeEquity && !hasSuccessManualPayment);
};

export const hasNegativeEquity = (
	priceBreakdown: PriceBreakdown[] | LatestPostSaleOffer['priceBreakdown'] | null | undefined,
): boolean => {
	if (!priceBreakdown) {
		return false;
	}

	if (Array.isArray(priceBreakdown)) {
		return priceBreakdown.some((item) => item.type === 'seller_amount' && item.amount < 0);
	}

	return priceBreakdown?.seller_amount?.amount < 0;
};

export const resolveActions = (actions: (React.ReactElement | null | undefined)[]): React.ReactElement[] => {
	const validActions = actions.filter<React.ReactElement>((action): action is React.ReactElement => Boolean(action));

	return validActions;
};

export const sleepWithAbort = (
	ms: number,
	opts: {
		forceDelay?: boolean;
		signal: AbortSignal | null;
		throwOnAbort?: boolean;
	},
): Promise<void> => {
	const { forceDelay = false, signal, throwOnAbort } = opts;

	const delay = ms ?? 3000;

	return new Promise((resolve, reject) => {
		if (delay === 0) {
			resolve();
			return;
		}

		if (signal?.aborted && !forceDelay) {
			resolve();
			return;
		}

		const timeoutId = setTimeout(() => {
			resolve();
		}, delay);

		if (signal) {
			signal.addEventListener(
				'abort',
				() => {
					clearTimeout(timeoutId);

					if (throwOnAbort) {
						reject(new Error('Aborted sleepWithAbort'));
					} else {
						resolve();
					}
				},
				{ once: true },
			);
		}
	});
};

export const getVehiclePaymentRoutesByEnquiryId = ({ enquiryId }: { enquiryId: string }) => {
	function createRoute<P extends VehiclePaymentRoutePathPrimary, K extends VehiclePaymentRoutePathSecondary<P>>(
		...params: [path?: P, subpath?: K]
	) {
		return generatePath<VehiclePaymentAvailableRoutes<P, K>>(ROUTES.VEHICLE_PAYMENT.href, {
			enquiryId,
			params,
		});
	}

	function createRouteWithDynamicSegment<P extends VehiclePaymentRoutePathPrimary, K extends `[${string}]`>(
		...params: [path?: P, subpath?: K]
	) {
		return `${createRoute(params[0])}/${params[1]}` as `${VehiclePaymentRoutePrefix}/${P}/${K}`;
	}

	const PATHS = {
		CANCEL: createRoute('cancel'),
		ENTRY: createRoute(),
		ERROR: createRoute('error'),
		INSUFFICIENT_FUNDS: createRoute('insufficient-funds'),
		INSUFFICIENT_FUNDS_TRANSFER_NEEDED: createRoute('insufficient-funds', 'transfer-needed'),
		INSUFFICIENT_FUNDS_TRANSFER_RECEIVED: createRoute('insufficient-funds', 'transfer-received'),
		NEGATIVE_EQUITY: createRoute('negative-equity'),
		PAYMENT_OPTIONS: createRouteWithDynamicSegment('payment-options', '[[...params]]'),
		PROCESSING_PAYMENT: createRoute('processing-payment'),
		SUCCESS: createRoute('payment-successful'),
		UNDISCLOSED_DAMAGE: createRoute('undisclosed-damage'),
		UNDISCLOSED_DAMAGE_AMEND_PRICE: createRoute('undisclosed-damage', 'amend-price'),
		UNDISCLOSED_DAMAGE_INFORM_SELLER: createRoute('undisclosed-damage', 'inform-seller'),
		UNDISCLOSED_DAMAGE_YOUR_REVISED_OFFER: createRoute('undisclosed-damage', 'your-revised-offer'),
	} as const;

	return {
		PATHS,
	};
};

export function getPaymentOptionCheckoutURL({
	key,
	PATHS,
}: {
	// eslint-disable-next-line local-rules/interfaces-types-properties-naming
	PATHS: ReturnType<typeof getVehiclePaymentRoutesByEnquiryId>['PATHS'];
	key: PaymentProviderKey;
}): string | null {
	try {
		const slug = PAYMENT_OPTION_URL_SLUG_BY_KEY.get(key);

		if (!slug) {
			return null;
		}

		return generatePath(PATHS.PAYMENT_OPTIONS, {
			params: [slug],
		});
	} catch (error) {
		const errorMessage = 'Failed to generate payment option checkout URL';

		logger.error({
			context: {
				paymentOptionKey: key,
			},
			error: new Error(errorMessage, { cause: error }),
			message: errorMessage,
			scope: 'VehiclePayment.helpers.getPaymentOptionCheckoutURL',
		});

		return null;
	}
}

export const useIsNegativeEquityVehicle = (): boolean => {
	const isNegativeEquityV01Enabled = useFeatureToggle('negativeEquityV01');
	const {
		state: {
			financeStatus: { isNegativeEquity: isNegativeEquityVehicle },
		},
	} = usePaymentsWizard();
	const isNegativeEquity = isNegativeEquityV01Enabled && isNegativeEquityVehicle;

	return isNegativeEquity;
};
