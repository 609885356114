import type React from 'react';

export const ROUTE_ELEMENT = 'Route';

const Route = <T extends UnknownShapeObject, N extends string = string, D extends UnknownShapeObject = {}>({
	element: Element,
}: {
	detail?: D;
	element: React.ReactElement;
	if?: (props: T) => boolean;
	name?: N;
	path?: string;
}): React.ReactElement => {
	return Element;
};

Route.displayName = ROUTE_ELEMENT;

export default Route;
