/* eslint-disable no-empty-function */
/* eslint-disable max-len */
import React from 'react';

import { ROUTE_ELEMENT } from './Route';
import { generatePath, matchPath } from './Router.helpers';
import type {
	RouteNode,
	RoutePropsWithPaths,
	WizardContext as WizardContextType,
	WizardGroupNode,
	WizardRouteProps,
	WizardRoutes,
} from './Wizard.types';
import { WIZARD_GROUP_ELEMENT } from './WizardGroup';

export class WizardError extends Error {
	constructor(message: string, options: ErrorOptions = {}) {
		super(`Wizard encountered problem: ${message}`, options);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WizardContext = React.createContext<WizardContextType<any>>({
	currentPage: '',
	currentPageIndex: -1,
	defaultRoutingStrategy: 'shallow',
	onWizardSubmit: async () => {},
	paths: [],
	routesProps: [],
	state: undefined,
	updateWizardState: () => {},
});

WizardContext.displayName = 'WizardContext';

export const resolveWizardNextPath = (
	paths: string[] | undefined | null,
	currentPageIndex: number | undefined | null,
): string | undefined => {
	if (!Array.isArray(paths)) {
		return undefined;
	}

	return paths?.[Math.min(Math.max(currentPageIndex ?? -1, -1) + 1, paths.length - 1)];
};

export const resolveWizardPreviousPath = (
	paths: string[] | undefined | null,
	currentPageIndex: number | undefined | null,
): string | undefined => {
	if (!Array.isArray(paths)) {
		return undefined;
	}

	return paths?.[Math.max(Math.min(currentPageIndex ?? -1, paths.length) - 1, 0)];
};

export const resolveWizardRoute = (
	routesProps: WizardRouteProps[] | undefined,
	pageName: string,
): WizardRouteProps | undefined => routesProps?.find?.((route) => route?.name === pageName);

export const isRouteElementType = (route: unknown): route is RouteNode => {
	// @ts-expect-error -- as `route` is type unknown, checking nested properties fails TS check, but it's safe with ?.
	return route?.type?.displayName === ROUTE_ELEMENT;
};

export const isWizardGroupElementType = (route: unknown): route is WizardGroupNode => {
	// @ts-expect-error -- as `route` is type unknown, checking nested properties fails TS check, but it's safe with ?.
	return route?.type?.displayName === WIZARD_GROUP_ELEMENT;
};

export const getRoutesPropsWithPaths = (routes: WizardRoutes[]): RoutePropsWithPaths[] => {
	if (!Array.isArray(routes)) {
		return [];
	}

	const routePropsWithPaths: RoutePropsWithPaths[] = [];

	for (const route of routes) {
		if (isRouteElementType(route) && route.props.path) {
			routePropsWithPaths.push(route.props as RoutePropsWithPaths);
		}
	}

	return routePropsWithPaths;
};

export const getRoutesPaths = (routes: WizardRoutes[]): string[] => {
	const paths: string[] = [];

	for (const props of getRoutesPropsWithPaths(routes)) {
		paths.push(props.path);
	}

	return paths;
};

export const parseWizardRoute = (path: string): string => generatePath(path, {});

export const getPageIndex = (page: string, paths: string[]): number =>
	paths?.findIndex?.((path) => !!matchPath(page, { path })) ?? -1;

export const getParsedRoutePaths = (paths: string[]): string[] => paths?.map?.(parseWizardRoute) || [];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const filterFalsyValues = <T>(values: T[]) =>
	values.filter((v) => (Array.isArray(v) ? v.length : v)) as T extends infer U
		? U extends undefined | null
			? never
			: U[]
		: T[];

export function isValidWizardNode(object: {} | null | undefined): object is WizardRoutes {
	return object !== null && object !== undefined && 'type' in object;
}

export const getAvailableRoutes = <T extends UnknownShapeObject>({
	checkIf = true,
	routes = [],
	state = {} as T,
}: {
	checkIf?: boolean;
	routes: React.ReactNode[] | WizardRoutes[];
	state?: T;
}): RouteNode[] => {
	const availableRoutes = routes.map((route) => {
		if (isValidWizardNode(route)) {
			if (isWizardGroupElementType(route)) {
				const isAvailable = checkIf && route.props.if ? route.props.if?.(state) : true;

				if (!isAvailable || !route.props.children) {
					return undefined;
				}

				const routeRoutes = React.Children.toArray(route.props.children) as WizardRoutes[];
				return filterFalsyValues(getAvailableRoutes({ checkIf, routes: routeRoutes, state }));
			}

			if (isRouteElementType(route)) {
				const isAvailable = checkIf && route.props.if ? route.props.if?.(state) : true;

				if (!isAvailable || !route.props.element) {
					return undefined;
				}

				return route;
			}
		}

		return undefined;
	});

	const result = filterFalsyValues(availableRoutes).flat(Infinity) as RouteNode[];

	return result;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const resolveAvailableRoutes = <T extends UnknownShapeObject>({
	checkIf,
	childrenArray,
	state,
}: {
	checkIf?: boolean;
	childrenArray: React.ReactNode[];
	state?: T;
}) => {
	const routes = getAvailableRoutes({ checkIf, routes: childrenArray, state });
	const rawPaths = getRoutesPaths(routes);
	const paths = getParsedRoutePaths(rawPaths);

	return { paths, rawPaths, routes };
};

export const resolveAllPossibleRoutes = ({
	childrenArray,
}: {
	childrenArray: React.ReactNode[];
}): ReturnType<typeof resolveAvailableRoutes> => resolveAvailableRoutes({ checkIf: false, childrenArray });

export const getWizardNextState = <P, N>(prevState: P, newState: N): P & N => ({
	...prevState,
	...newState,
});

export const resolveCurrentPageRef = ({
	currentPage,
	currentPageRef,
	transitionPage,
}: {
	currentPage: string;
	currentPageRef: React.MutableRefObject<string>;
	transitionPage: React.MutableRefObject<string | null>;
}): string => {
	/**
	 * React state will be updated faster than router and browser URL
	 * this is why if `Wizard` sees that transition from one page to another
	 * is in progress, it will start using `transitionPage` reference (next page URL)
	 * to render next page.
	 *
	 * This is important because, without it, `Wizard` would render the previous page
	 * or what worse, it could render the `Redirect`, as the previous page may not
	 * be available anymore, with updated state.
	 *
	 * We use `.toString()` here, as we want to assign `transitionPage` value
	 * to the `currentPage` variable, not its reference, as setting `null` to
	 * `transitionPage` would also nullify `currentPage`
	 */
	const isInTransition = !!transitionPage.current && transitionPage.current !== currentPageRef.current;
	return isInTransition ? (transitionPage.current || '').toString() : currentPage;
};

export function getAtIndex<P>(array: P[], index: number): P {
	return (array ?? [])[index < 0 ? array.length + index : index];
}
