import { MDText } from 'i18n-react';

import { toast } from '@motorway/mw-highway-code/pre-alpha';

import { GA_OTP_LABEL, OTP_ACTION } from 'Components/pages/VehiclePayment/VehiclePayment.const';
import {
	POPUP_APPEAR_SCHEMA,
	triggerOtpAnalyticsEvent,
} from 'Services/analytics/events/paymentEvents/otpSnowplowEvents';

import LocalTexts from './AuthenticationFeedback.texts';

export const OTP_SUCCESS_TOAST_DURATION = 5000;

const LocalT = new MDText(LocalTexts);

export const triggerSuccessOTPToast = (): void => {
	toast({
		content: LocalT.translate('successToast.content'),
		duration: OTP_SUCCESS_TOAST_DURATION,
		heading: LocalT.translate('successToast.heading'),
		variant: 'success',
	});

	triggerOtpAnalyticsEvent({
		customData: {
			label: GA_OTP_LABEL.OTP_SETUP_SUCCESS_SHOWN,
		},
		name: OTP_ACTION.OTP_SETUP_SUCCESS_SHOWN,
		schema: POPUP_APPEAR_SCHEMA,
	});
};
