import { useRouter } from 'next/router';

import { useBilling } from 'Components/Billing/useBilling';
import cypressIds from 'Components/cypress_ids.json';
import { defaultWalletUrl } from 'Components/pages/Wallet/Wallet.const';
import { triggerNavigationAvatarClickedEvent } from 'Services/analytics/events';
import { Content, MenuItem, Trigger } from 'Storybook/core/Menu';
import { triggerUserSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import type { AccountMenuProps } from 'Storybook/local/SiteNavigation/SiteNavigation.types';
import { useTrade } from 'Storybook/local/SiteNavigation/utilities/hooks/useTrade';
import Avatar from 'Storybook/peripheral/Avatar/Avatar';
import { Variant } from 'Types/variant';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import usePaymentsMVPEnabled from 'Utilities/hooks/usePaymentsMVPEnabled';
import usePaymentsPermission from 'Utilities/hooks/usePaymentsPermission';

import { useSignOut } from '../../../utilities/hooks/useSignOut';
import { AccountMenuHeader, AccountMenuHeaderLegacy } from '../AccountMenuHeader/AccountMenuHeader';

import { getAccountMenuLinks } from './AccountMenu.helpers';

import styles from './AccountMenu.module.scss';

export const AccountMenu: React.FC<AccountMenuProps> = ({ dealerName, dealershipName, router }) => {
	const isPaymentMVPEnable = usePaymentsMVPEnabled();
	const hasPaymentsPermission = usePaymentsPermission();
	const isAccountSettingsEnabled = useFeatureToggle(FEATURES.accountSettings);

	const { isBillingEnabled, onBillingClick, viewElement } = useBilling();
	const { onSignOut } = useSignOut();
	const { push } = useRouter();

	const onWalletClick = () => push(defaultWalletUrl);

	const { isTradeListingsEnabled, onTradeListingsClick } = useTrade();

	const listItems = getAccountMenuLinks({
		isBillingEnabled,
		isTradeListingsEnabled,
		isWalletEnabled: isPaymentMVPEnable && hasPaymentsPermission,
		onBillingClick,
		onSignOutClick: onSignOut,
		onTradeListingsClick,
		onWalletClick,
	});

	const headerContent = isAccountSettingsEnabled ? (
		<AccountMenuHeader dealerName={dealerName} dealershipName={dealershipName} />
	) : (
		<AccountMenuHeaderLegacy dealerName={dealerName} dealershipName={dealershipName} />
	);

	return (
		<>
			{viewElement}
			<MenuItem className={styles.menuItem}>
				<Trigger
					asChild
					onClick={() => {
						triggerUserSiteNavigationGAEvent('avatar');
						triggerNavigationAvatarClickedEvent();
					}}
				>
					<Avatar cypressId={cypressIds.buttons.accountMenu} data-testid="accountMenu" fullName={dealerName} />
				</Trigger>
				<Content
					cypressId={cypressIds.containers.accountMenuContent}
					headerContent={headerContent}
					lists={listItems}
					router={router}
					side="right"
					size="large"
					variant={Variant.SECONDARY}
				/>
			</MenuItem>
		</>
	);
};
