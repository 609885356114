import React, { useCallback, useRef, useState } from 'react';
import cx from 'classnames';
import { Button } from '@THC/components/Button/Button';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import type { InputAuthProps } from '@motorway/mw-highway-code';
import { InputAuth } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { useConstantCallback } from '@motorway/mw-highway-code/hooks';

import {
	AuthenticationFeedbackWithOtp,
	AuthenticationFeedbackWithoutOtp,
} from 'Components/AuthenticationFeedback/AuthenticationFeedback';
import cypressIds from 'Components/cypress_ids.json';
import { matchPath } from 'Components/shared/Wizard/Router.helpers';
import { SNOWPLOW_BACKEND_SERVICES } from 'Services/analytics/analytics.const';
import { otpEventsBackendTrigger } from 'Services/analytics/events/paymentEvents/otpEvents';
import {
	CLICK_SCHEMA,
	CTA_CLICKED_SCHEMA,
	triggerOtpAnalyticsEvent,
} from 'Services/analytics/events/paymentEvents/otpSnowplowEvents';
import { applyCypressData } from 'Utilities/helpers';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import { ALLOWED_KYC_STATUSES_FOR_OTP_MODAL, useHandleKycStatus } from 'Utilities/hooks/useHandleKycStatus';
import { useStoreOtpPhoneValidated } from 'Utilities/hooks/useStoreOtpPhoneValidated';
import useUser from 'Utilities/hooks/useUser';
import type { KycStatus } from 'Utilities/types/kyc.types';

import { GA_OTP_LABEL, OTP_ACTION } from '../../VehiclePayment.const';
import { PHONES } from '../../VehiclePayment.helpers';
import Texts from '../../VehiclePayment.texts.json';

import {
	AuthenticationFeedbackOtpDisabled,
	FeedbackHeader,
	getSubDescState,
	InfoHeader,
	OTP_MUST_BE_VALIDATED_PAGES,
} from './OTPContent.helper';
import { type OTPContentProps, type SubDesc, OtpAction } from './OTPContent.types';
import { useOTP } from './useOTP';

import styles from './OTPContent.module.scss';

const OTP_MUST_BE_VALIDATED_PAGES_ARRAY = Array.from(OTP_MUST_BE_VALIDATED_PAGES);

const LocalT = new MDText(Texts.OTPModal);

export const useOTPContentLogic = ({ enquiryId, logs }: Pick<OTPContentProps, 'logs' | 'enquiryId'>) => {
	const { asPath } = useRouter();

	const { otpPhone, phoneConfirmedAt } = useUser();
	const { kycStatus } = useHandleKycStatus({ source: 'setup' });

	const [status, setStatus] = useState<'init' | 'setupOtp' | 'error' | 'success'>('init');
	const [isStoreOtpPhoneValidated] = useStoreOtpPhoneValidated(false);

	const enquiryIdForLogs = logs.source === 'createPostSaleOffer' ? enquiryId : undefined;
	const hasAllowSetupKycStatus =
		kycStatus === undefined ? true : ALLOWED_KYC_STATUSES_FOR_OTP_MODAL.has(kycStatus as KycStatus);

	const isOTPSetupRequired = Boolean(
		OTP_MUST_BE_VALIDATED_PAGES_ARRAY.find((path) => Boolean(matchPath(asPath, { path }))),
	);

	const isPaymentsOTPFeatureEnabled = useFeatureToggle(FEATURES.paymentsOTP);

	const hasPhoneMissing = !otpPhone || phoneConfirmedAt === null;
	const isOTPDisabled = hasPhoneMissing && !isStoreOtpPhoneValidated;
	const showAuthenticationFeedbackWithOtp =
		((phoneConfirmedAt === null && status === 'init') || status === 'error') && !isStoreOtpPhoneValidated;

	return {
		enquiryIdForLogs,
		hasAllowSetupKycStatus,
		isOTPDisabled,
		isOTPSetupRequired,
		isPaymentsOTPFeatureEnabled,
		otpPhone,
		setStatus,
		showAuthenticationFeedbackWithOtp,
		status,
	};
};

const OTPContent = ({
	bottomDesc,
	ctaNoNumber = `${LocalT.translate('CTANoNumber')}`,
	ctaNoNumberSupport = LocalT.translate('CTANoNumberSupport', {
		number: <Hyperlink href={`tel:${PHONES.PAYMENT_ERROR.replace(/ /g, '')}`} label={PHONES.PAYMENT_ERROR} />,
	}),
	ctaResend = `${LocalT.translate('CTAResend')}`,
	ctaWait = `${LocalT.translate('CTAWait')}`,
	desc,
	enquiryId = undefined,
	extraStyles = { paymentsOTPContent: undefined, title: undefined },
	logs,
	request,
	title = `${LocalT.translate('title')}`,
}: OTPContentProps): React.ReactElement => {
	const { asPath } = useRouter();

	const [subDesc, setSubDesc] = useState<SubDesc | undefined>(undefined);
	const errorAttempt = useRef<number>(0);

	const staticRequest = useConstantCallback(request);

	const {
		enquiryIdForLogs,
		hasAllowSetupKycStatus,
		isOTPDisabled,
		isOTPSetupRequired,
		isPaymentsOTPFeatureEnabled,
		otpPhone,
		setStatus,
		showAuthenticationFeedbackWithOtp,
		status,
	} = useOTPContentLogic({ enquiryId, logs });

	const { getCode, readableCountDown, resendButtonAvailable } = useOTP({
		disabled: isOTPDisabled && status !== 'setupOtp',
		enquiryId: enquiryIdForLogs,
		otpAction: status === 'setupOtp' ? OtpAction.VERIFY_PHONE : undefined,
		source: logs.source,
	});

	const getDescription = useCallback(() => {
		if (desc) return desc;

		if (otpPhone) {
			const endOfPhoneNo = otpPhone.slice(otpPhone.length - 3, otpPhone.length);
			return `${LocalT.translate('desc', { number: endOfPhoneNo })}`;
		}

		return null;
	}, [desc, otpPhone]);

	const handleResendClick = useCallback(() => {
		getCode();

		otpEventsBackendTrigger({
			action: OTP_ACTION.REQUEST_NEW_OTP,
			backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
			label: GA_OTP_LABEL.REQUEST_NEW_OTP,
		});
	}, [getCode]);

	const handleOnFill: InputAuthProps['onFill'] = useCallback(
		({ onError, onSuccess, value }) => {
			staticRequest(value)
				.then((cb) => {
					errorAttempt.current = 0;

					setSubDesc(getSubDescState(`${LocalT.translate('subDesc.success')}`, 'success'));
					onSuccess(cb);
					setStatus('success');

					otpEventsBackendTrigger({
						action: OTP_ACTION.OTP_SUCCESSFUL,
						backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_SUCCESSFUL,
						label: GA_OTP_LABEL.OTP_SUCCESSFUL,
					});
				})
				.catch((customError) => {
					errorAttempt.current += 1;

					otpEventsBackendTrigger({
						action: `${OTP_ACTION.INCORRECT_PASSWORD_ATTEMPT_NUMBER} ${errorAttempt.current}`,
						backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_INCORRECT_PASSWORD_ATTEMPT_NUMBER,
						label: `${GA_OTP_LABEL.INCORRECT_PASSWORD}_${errorAttempt.current}`,
					});

					onError();

					if (!customError) {
						setSubDesc(getSubDescState(`${LocalT.translate('subDesc.notMach')}`, 'error'));
					} else if (customError.message === 'verification_limit_reached') {
						setSubDesc(getSubDescState(`${LocalT.translate('subDesc.codeSent')}`));
						otpEventsBackendTrigger({
							action: OTP_ACTION.REQUEST_NEW_OTP,
							backend_service: SNOWPLOW_BACKEND_SERVICES.OTP_NEW_REQUEST,
							label: GA_OTP_LABEL.REQUEST_NEW_OTP,
						});
					} else if (customError.message === 'rate_limit_reached') {
						setSubDesc(getSubDescState(`${LocalT.translate('subDesc.rateLimit')}`, 'error'));
					} else if (isPaymentsOTPFeatureEnabled && isOTPDisabled) {
						setStatus('error');
					} else
						setSubDesc(
							getSubDescState(
								<span>
									{LocalT.translate('subDesc.unknown', {
										phone: (
											<Hyperlink
												inherit
												href={`tel:${PHONES.PAYMENT_ERROR.replace(/ /g, '')}`}
												label={PHONES.PAYMENT_ERROR}
											/>
										),
									})}
								</span>,
								'error',
							),
						);
				});
		},
		[isOTPDisabled, isPaymentsOTPFeatureEnabled, staticRequest, setStatus],
	);

	const handleAuthenticationFeedbackWithOtpOnClick = useCallback(() => {
		triggerOtpAnalyticsEvent({
			customData: {
				button_label: 'Set up authentication number',
				label: GA_OTP_LABEL.OTP_SETUP_BUTTON_CLICKED,
				url: asPath,
			},
			name: OTP_ACTION.OTP_SETUP_BUTTON_CLICKED,
			schema: CTA_CLICKED_SCHEMA,
		});

		setStatus('setupOtp');
	}, [asPath, setStatus]);

	const handleAuthenticationFeedbackWithOtpOnPhoneClick = useCallback(() => {
		triggerOtpAnalyticsEvent({
			customData: {
				label: GA_OTP_LABEL.OTP_PHONE_NUMBER_CLICKED,
			},
			name: OTP_ACTION.OTP_PHONE_NUMBER_CLICKED,
			schema: CLICK_SCHEMA,
		});
	}, []);

	// If we are in a page that requires OTP validation and the phone is not validated, show the disabled screen
	if (isOTPSetupRequired && isOTPDisabled) {
		return (
			<AuthenticationFeedbackOtpDisabled
				ctaNoNumber={ctaNoNumber}
				ctaNoNumberSupport={ctaNoNumberSupport}
				extraStyles={extraStyles}
				title={title}
			/>
		);
	}

	// If paymentsOTP FF is enabled, check for new holding screens
	if (isPaymentsOTPFeatureEnabled) {
		// If OTP phone is not defined for the dealer, display the banner to contact support
		if (!otpPhone || !hasAllowSetupKycStatus) {
			return <AuthenticationFeedbackWithoutOtp onPhoneClick={handleAuthenticationFeedbackWithOtpOnPhoneClick} />;
		}

		// If phone is not confirmed or there is an error in OTP status display the setup screen
		if (showAuthenticationFeedbackWithOtp) {
			return (
				<AuthenticationFeedbackWithOtp
					authenticationPhoneNumber={otpPhone}
					onClick={handleAuthenticationFeedbackWithOtpOnClick}
					onPhoneClick={handleAuthenticationFeedbackWithOtpOnPhoneClick}
					showWarning={status === 'error'}
				/>
			);
		}
	}

	// If paymentsOTP feature is not enabled, retain the old behavior
	// This will be removed once the feature is fully enabled
	if (!isPaymentsOTPFeatureEnabled) {
		if (isOTPDisabled) {
			return (
				<AuthenticationFeedbackOtpDisabled
					ctaNoNumber={ctaNoNumber}
					ctaNoNumberSupport={ctaNoNumberSupport}
					extraStyles={extraStyles}
					title={title}
				/>
			);
		}
	}

	// OtpForm component
	return (
		<div
			{...applyCypressData(cypressIds.payments.otp.otpModal)}
			className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}
		>
			<div>
				<InfoHeader description={getDescription()} extraStyles={extraStyles} title={title} />
				<div className={styles.authenticateBox}>
					<FeedbackHeader subDesc={subDesc} />
					<InputAuth
						{...applyCypressData(cypressIds.inputs.paymentOTP)}
						aria-describedby={subDesc ? subDesc.id : undefined}
						data-testid="input-otp"
						id="payment-otp"
						onFill={handleOnFill}
					/>
				</div>
			</div>

			<div {...applyCypressData(cypressIds.payments.button.resendButton)} className={styles.buttonWrapper}>
				<Button
					disabled={!resendButtonAvailable}
					label={resendButtonAvailable ? ctaResend : `${ctaWait} ${readableCountDown}`}
					onClick={handleResendClick}
					variant={resendButtonAvailable ? 'primary' : 'tertiary'}
				/>
			</div>
			{bottomDesc}
		</div>
	);
};

export default OTPContent;
