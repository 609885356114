import type { InfiniteData } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import omit from 'lodash/omit';

import { VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { queryClient } from 'Config/reactQueryConfig';
import { getAdditionalVehicleDetails } from 'Services/api/additional-vehicle-details';
import { getVehicles } from 'Services/api/vehicles';
import type { AdditionalVehicleDetailsFromDynamoDb, ListingsTableSaleType } from 'Services/dynamoDb/dynamoDb.types';
import { logger } from 'Services/logger/logger';
import { LIST_TYPE_TO_LISTINGS_TABLE_SALE_TYPE } from 'Stores/AdditionalVehicleDetailsStore/AdditionalVehicleDetailsStore.const';
import { mapStateSlugToListingsTableSaleType } from 'Stores/AdditionalVehicleDetailsStore/AdditionalVehicleDetailsStore.helpers';
import type { ListType } from 'Types/listType';
import { LIST_TYPES } from 'Utilities/consts';
import { FetchVehicleListError } from 'Utilities/error/errors';

import type { UseVehicleListQuery } from '../useVehicleListQuery/useVehicleListQuery';

import { VEHICLE_LIST_QUERY_KEY } from './useFetchVehicleList';
import type {
	CachedVehicleListData,
	CheckPreviewOrAuctionDuringActiveProps,
	CheckVehiclesAreFetchedDuringSaleProps,
	MapVehicleListWithAdditionalDetailsParams,
	VehicleListFetcherParams,
} from './useFetchVehicleList.helpers.types';
import type { AllPages, GetPaginatedListOfIdsParams, VehicleListFetcher } from './useFetchVehicleList.types';

const FIELDS_TO_OMIT = ['timeId'];

export const getLoadedVehiclesCount = (allPages: AllPages = []): number =>
	allPages?.reduce((acc, page) => {
		const totalPerPage = page?.data?.vehicles?.length || 0;
		acc += totalPerPage;
		return acc;
	}, 0);

// eslint-disable-next-line local-rules/function-naming
export const vehicleListFetcher = async ({
	isUsingCachedData,
	pageParam,
	queryKey,
	signal,
}: VehicleListFetcherParams): Promise<VehicleListFetcher> => {
	const [, query = {}] = queryKey;
	const currentPage = pageParam?.nextPage || 0;
	const loadedCount = pageParam?.loadedCount || 0;
	const isFirstPage = currentPage === 0;
	const shouldOmitFields = Boolean(!isUsingCachedData && isFirstPage);
	const fieldsToOmit = shouldOmitFields ? FIELDS_TO_OMIT : [];
	const data = await getVehicles({ loadedCount, page: currentPage, ...omit(query, fieldsToOmit) }, signal);

	return {
		data,
		nextPage: data?.hasMore ? currentPage + 1 : undefined,
	};
};

const isUserOnAuctionDuringSaleHours = ({ filterQuery, isSaleActive }: CheckPreviewOrAuctionDuringActiveProps) => {
	const params = new URLSearchParams(filterQuery);
	const listType = params.get(VEHICLE_LIST_FILTER_NAME.LIST_TYPE);

	return isSaleActive && listType === LIST_TYPES.auction;
};

export const checkVehiclesAreFetchedDuringSale = ({
	appliedFilterCount,
	isSaleActive,
	query,
	vehicleCount = 0,
}: CheckVehiclesAreFetchedDuringSaleProps): void => {
	const hasVehicles = vehicleCount > 0;

	if (hasVehicles) {
		return;
	}

	const isAuctionDuringSaleHours = isUserOnAuctionDuringSaleHours({
		filterQuery: query.filterQuery,
		isSaleActive,
	});

	if (isAuctionDuringSaleHours && appliedFilterCount === 0) {
		logger.error({
			error: new FetchVehicleListError(`checkVehiclesAreFetchedDuringSale`),
			message: `No vehicles were fetched during sale hours`,
			scope: 'useFetchVehicleList helpers',
		});
	}
};

const getLatestVehicleListPage = (vehicleData: InfiniteData<VehicleListFetcher>) => {
	const lastIndex = (vehicleData?.pages?.length || 1) - 1;
	const lastPage = vehicleData?.pages?.[lastIndex];
	return { lastIndex, lastPage };
};

const getPaginatedListOfIds = ({ lastIndex, lastPage }: GetPaginatedListOfIdsParams) => {
	const vehicleIds = lastPage?.data?.vehicles?.map((vehicle) => vehicle.id);
	const pageNumber = lastIndex + 1;

	return `${pageNumber}: ${vehicleIds?.join(',')}`;
};

export const getLatestVehicleEnquiryIds = (vehicleData: InfiniteData<VehicleListFetcher>): string => {
	const { lastIndex, lastPage } = getLatestVehicleListPage(vehicleData);
	const hasVehicles = Boolean(lastPage?.data?.totalCount);

	if (!hasVehicles) {
		return '';
	}

	return getPaginatedListOfIds({ lastIndex, lastPage });
};

export const getAdditionalVehicleDetailsAsync = async (
	saleType?: ListingsTableSaleType,
): Promise<AdditionalVehicleDetailsFromDynamoDb> => {
	try {
		const additionalVehicleDetails = await getAdditionalVehicleDetails(saleType ? { saleType } : undefined);
		return additionalVehicleDetails;
	} catch (error) {
		logger.error({
			error: error as Error,
			message: 'Error fetching additional vehicle details',
			scope: 'useFetchVehicleList helpers',
		});
		return {};
	}
};

const getAdditionalVehicleDetailsFromListType = async (
	listType: ListType,
): Promise<AdditionalVehicleDetailsFromDynamoDb> => {
	const saleType = LIST_TYPE_TO_LISTINGS_TABLE_SALE_TYPE[listType];
	const additionalVehicleDetails = await getAdditionalVehicleDetailsAsync(saleType);

	return additionalVehicleDetails;
};

export const mapVehicleListWithAdditionalDetails = async ({
	additionalVehicleDetails,
	listType,
	query,
	setAdditionalVehicleDetails,
	vehicleList,
}: MapVehicleListWithAdditionalDetailsParams): Promise<VehicleListFetcher> => {
	const cachedData: CachedVehicleListData | undefined = queryClient.getQueryData([VEHICLE_LIST_QUERY_KEY, query] as [
		string,
		UseVehicleListQuery,
	]);

	const vehicles = vehicleList?.data?.vehicles;
	const cachedVehicles = cachedData?.pages?.flatMap((page) => page.data.vehicles);
	const allVehicles = vehicles.concat(cachedVehicles ?? []);
	const vehicleIds = allVehicles?.map((vehicle) => vehicle.id);
	const saleType = LIST_TYPE_TO_LISTINGS_TABLE_SALE_TYPE[listType];

	const missingVehicleIds = saleType
		? vehicleIds.filter((id) => !additionalVehicleDetails[saleType]?.[id])
		: vehicleIds;

	if (!isEmpty(missingVehicleIds)) {
		const fetchedAdditionalVehicleDetails = await getAdditionalVehicleDetailsFromListType(listType);
		setAdditionalVehicleDetails({ ...additionalVehicleDetails, ...fetchedAdditionalVehicleDetails });
	}

	const mappedVehicles = !isEmpty(additionalVehicleDetails)
		? vehicles?.map((vehicle) => {
				const listingsType = mapStateSlugToListingsTableSaleType(vehicle?.state?.slug);
				const additionalDetails = (listingsType && additionalVehicleDetails[listingsType]?.[vehicle.id]) ?? {};
				return { ...vehicle, ...additionalDetails };
		  })
		: vehicles;

	return { ...vehicleList, data: { ...vehicleList.data, vehicles: mappedVehicles } };
};
