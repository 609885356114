import type { ReactNode } from 'react';
import React from 'react';
import type { Argument } from 'classnames';
import cx from 'classnames';

import { IconText, InfoIcon, TickCircleIcon, WarningIcon } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import { applySessionReplayMask } from 'Services/datadog/datadogRum';
import { MASKED_EVENT_VALUES } from 'Services/datadog/datadogRum.const';
import { applyCypressData } from 'Utilities/helpers';
import ROUTES from 'Utilities/routes';

import type { SubDesc, SubDescType } from './OTPContent.types';

import styles from './OTPContent.module.scss';

export const OTP_MUST_BE_VALIDATED_PAGES = new Set<string>([ROUTES.VEHICLE_PAYMENT.href, ROUTES.WITHDRAW_FUNDS.href]);

const { OTP } = MASKED_EVENT_VALUES;
const privacyParams = { description: applySessionReplayMask({ maskedActionName: OTP.description }) };

export const AuthenticationFeedbackOtpDisabled: React.FC<{
	ctaNoNumber: string;
	ctaNoNumberSupport: ReactNode;
	extraStyles: Record<string, Argument>;
	title: ReactNode;
}> = ({ ctaNoNumber, ctaNoNumberSupport, extraStyles, title }) => {
	return (
		<div className={cx(styles.paymentsOTPContent, extraStyles.paymentsOTPContent)}>
			<div>
				<h1 className={cx(styles.title, extraStyles.title)}>{title}</h1>
				<div className={styles.desc}>
					<p>{ctaNoNumber}</p>
					<p>{ctaNoNumberSupport}</p>
				</div>
			</div>
		</div>
	);
};

export const InfoHeader: React.FC<{
	description: ReactNode;
	extraStyles: Record<string, Argument>;
	title: ReactNode;
}> = ({ description, extraStyles, title }) => {
	return (
		<>
			<h1 {...applyCypressData(cypressIds.payments.otp.otpTitle)} className={cx(styles.title, extraStyles.title)}>
				{title}
			</h1>
			<div className={styles.desc} {...privacyParams.description}>
				<p {...applyCypressData(cypressIds.payments.otp.otpDescription)}>{description}</p>
			</div>
		</>
	);
};

export const FeedbackHeader: React.FC<{ subDesc?: SubDesc }> = ({ subDesc }) => {
	return (
		<div className={cx(styles.subDesc, styles[subDesc?.type as string])} id={subDesc?.id}>
			{subDesc && <IconText icon={subDesc.icon} text={subDesc.text} variant="xsmall--small" />}
		</div>
	);
};

const getIconForSubDesc = (type?: SubDescType) => {
	if (type === 'error') {
		return WarningIcon;
	}
	if (type === 'success') {
		return TickCircleIcon;
	}
	return InfoIcon;
};

export const getSubDescState = (text: React.ReactNode, type?: SubDescType): SubDesc => ({
	icon: getIconForSubDesc(type),
	id: 'payment-otp-sub-desc',
	text,
	type,
});
