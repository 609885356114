import type { Argument } from 'classnames';

import type { IconTextProps } from '@motorway/mw-highway-code';

import type { OtpSetupModalProps } from 'Components/OtpSetupModal/OtpSetupModal.types';

import type { OTPSource } from './useOTP.helper';

export interface OTPContentProps {
	bottomDesc?: React.ReactNode;
	ctaNoNumber?: string;
	ctaNoNumberSupport?: string | null | React.ReactNode;
	ctaResend?: string;
	ctaWait?: string;
	desc?: React.ReactNode;
	enquiryId?: number | undefined;
	extraStyles?: Record<string, Argument>;
	logs: Prettify<
		{
			source: OTPSource;
		} & Pick<OtpSetupModalProps, 'origin'>
	>;
	request: (verificationCode: string) => Promise<MaybePromiseCallback | undefined>;
	title?: React.ReactNode;
}

export type SubDescType = 'success' | 'error';

export interface SubDesc {
	icon: IconTextProps['icon'];
	id: string;
	text: React.ReactNode;
	type?: SubDescType;
}

export enum OtpAction {
	VERIFY_PHONE = 'verify-phone',
}
