/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback, useContext } from 'react';
import { useRouter } from 'next/router';

import {
	parseWizardRoute,
	resolveWizardNextPath,
	resolveWizardPreviousPath,
	resolveWizardRoute,
	WizardContext,
} from './Wizard.helpers';
import type { WizardContext as WizardContextType } from './Wizard.types';

export const useWizard = <T>() => useContext<WizardContextType<T>>(WizardContext);

export const useShouldShallowRoute = ({ shallow }: { shallow?: boolean } = {}) => {
	const { defaultRoutingStrategy } = useWizard();

	return shallow ?? defaultRoutingStrategy === 'shallow';
};

export const useCurrentPage = () => {
	const { asPath } = useRouter();

	return asPath;
};

export const useWizardFirstPage = () => {
	const { paths } = useWizard();

	return paths[0];
};

export const useWizardGoToFirstPage = ({ shallow }: { shallow?: boolean } = {}) => {
	const firstPage = useWizardFirstPage();
	const { push } = useRouter();

	const shouldShallowRoute = useShouldShallowRoute({ shallow });

	const goToFirstPage = useCallback(
		() => push(firstPage, undefined, { shallow: shouldShallowRoute }),
		[firstPage, push, shouldShallowRoute],
	);

	return goToFirstPage;
};

export const useWizardLastPage = () => {
	const { paths } = useWizard();

	return paths[paths.length - 1];
};

export const useWizardGoToLastPage = ({ shallow }: { shallow?: boolean } = {}) => {
	const lastPage = useWizardLastPage();
	const { push } = useRouter();

	const shouldShallowRoute = useShouldShallowRoute({ shallow });

	const goToLastPage = useCallback(
		() => push(lastPage, undefined, { shallow: shouldShallowRoute }),
		[lastPage, push, shouldShallowRoute],
	);

	return goToLastPage;
};

export const useWizardNextPage = () => {
	const { currentPageIndex, paths } = useWizard();

	return resolveWizardNextPath(paths, currentPageIndex);
};

export const useWizardGoToNextPage = ({ shallow }: { shallow?: boolean } = {}) => {
	const nextPage = useWizardNextPage();
	const { push } = useRouter();

	const shouldShallowRoute = useShouldShallowRoute({ shallow });

	const goToNextPage = useCallback(
		() => (nextPage ? push(nextPage, undefined, { shallow: shouldShallowRoute }) : Promise.resolve(false)),
		[nextPage, push, shouldShallowRoute],
	);

	return goToNextPage;
};

export const useWizardPreviousPage = () => {
	const { currentPageIndex, paths } = useWizard();

	return resolveWizardPreviousPath(paths, currentPageIndex);
};

export const useWizardGoToPreviousPage = ({ shallow }: { shallow?: boolean } = {}) => {
	const previousPage = useWizardPreviousPage();
	const { push } = useRouter();

	const shouldShallowRoute = useShouldShallowRoute({ shallow });

	const goToPrevPage = useCallback(
		() => (previousPage ? push(previousPage, undefined, { shallow: shouldShallowRoute }) : Promise.resolve(false)),
		[previousPage, push, shouldShallowRoute],
	);

	return goToPrevPage;
};

export const useWizardPage = <N extends string = string>(pageName: N) => {
	const { routesProps } = useWizard();
	const route = resolveWizardRoute(routesProps, pageName);

	if (!route || !route?.path) {
		return undefined;
	}

	return parseWizardRoute(route?.path);
};

export const useWizardGoToPage = <N extends string = string>(pageName: N, { shallow }: { shallow?: boolean } = {}) => {
	const page = useWizardPage(pageName);
	const { push } = useRouter();

	const shouldShallowRoute = useShouldShallowRoute({ shallow });

	const goToPage = useCallback(
		() => (page ? push(page, undefined, { shallow: shouldShallowRoute }) : Promise.resolve(false)),
		[page, push, shouldShallowRoute],
	);

	return goToPage;
};

export const useWizardPageElement = <N extends string = string>(pageName: N) => {
	const { routesProps } = useWizard();
	const route = resolveWizardRoute(routesProps, pageName);

	if (!route || !route.element) {
		return undefined;
	}

	return route.element;
};

export const useWizardIsLastPage = () => {
	const { currentPageIndex, paths } = useWizard();

	return currentPageIndex === paths.length - 1;
};

export const useWizardIsFirstPage = () => {
	const { currentPageIndex } = useWizard();

	return currentPageIndex === 0;
};

export const useWizardNavigation = () => {
	const isFirstPage = useWizardIsFirstPage();
	const isLastPage = useWizardIsLastPage();
	const nextPage = useWizardNextPage();
	const previousPage = useWizardPreviousPage();

	return {
		isFirstPage,
		isLastPage,
		nextPage,
		previousPage,
	};
};
