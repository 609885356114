import type { ReactElement } from 'react';
import React, { forwardRef } from 'react';
import cx from 'classnames';
import { NavigationMenuLink } from '@radix-ui/react-navigation-menu';
import NextLink from 'next/link';

import { HorizontalAlignment } from 'Types/generic';
import { Variant } from 'Types/variant';

import type { LinkItemProps } from '../../Menu.types';

import { getItemClass } from './Link.helpers';

import styles from './Link.module.scss';

type ItemContentProps = Pick<
	LinkItemProps,
	'icon' | 'isListItem' | 'id' | 'text' | 'extensionContent' | 'extensionPosition'
>;

const ItemContent = ({
	extensionContent: ExtensionContent,
	extensionPosition = HorizontalAlignment.Right,
	icon: Icon,
	id,
	isListItem,
	text,
}: ItemContentProps): ReactElement =>
	isListItem ? (
		<>
			{Icon && <Icon aria-hidden="true" data-testid={`${id}-icon`} size="small" />}
			{text}
			{ExtensionContent && (
				<div
					className={cx(styles.extensionPosition, {
						[styles.extensionPositionRight]: extensionPosition === HorizontalAlignment.Right,
						[styles.extensionPositionLeft]: extensionPosition === HorizontalAlignment.Left,
					})}
				>
					<ExtensionContent wrapperClassName={styles.extensionContentWrapper} />
				</div>
			)}
		</>
	) : (
		<span>{text}</span>
	);

const LinkItem = forwardRef<HTMLAnchorElement | HTMLButtonElement, LinkItemProps>(
	(
		{
			as,
			extensionContent,
			extensionPosition,
			href,
			icon,
			id,
			isActive,
			isIconRight = false,
			isListItem = false,
			onClick,
			text,
			variant = Variant.PRIMARY,
			...props
		},
		forwardedRef,
	): ReactElement => {
		const content = (
			<ItemContent
				extensionContent={extensionContent}
				extensionPosition={extensionPosition}
				icon={icon}
				id={id}
				isListItem={isListItem}
				text={text}
			/>
		);

		if (href) {
			return (
				<NextLink
					ref={forwardedRef as React.Ref<HTMLAnchorElement>}
					as={as}
					className={getItemClass(variant, isActive, isListItem, isIconRight)}
					data-thc-style-override="true"
					href={href}
					onClick={onClick}
					{...props}
				>
					{content}
				</NextLink>
			);
		}

		return (
			<button
				ref={forwardedRef as React.Ref<HTMLButtonElement>}
				className={getItemClass(variant, isActive, isListItem, isIconRight)}
				onClick={onClick}
				{...props}
			>
				{content}
			</button>
		);
	},
);

const Link: React.FC<LinkItemProps> = (props) => (
	<NavigationMenuLink asChild active={props.isActive}>
		<LinkItem {...props} />
	</NavigationMenuLink>
);

export default Link;
