/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useRef } from 'react';
import noop from 'lodash/noop';

import { useConstantCallback } from '@motorway/mw-highway-code/hooks';

import { getKycStatus } from 'Services/api/kyc';
import { logger } from 'Services/logger/logger';
import { useKycStore } from 'Stores/KycStore/KycStore';
import { DEALER_STATUS, MINUTE } from 'Utilities/consts';
import { isSSR } from 'Utilities/helpers';
import { getDataFromResponse } from 'Utilities/helpers/getDataFromResponse';
import { KycStatus } from 'Utilities/types/kyc.types';

import useFeatureToggle, { FEATURES } from './useFeatureToggle';
import type { UseHandleKycStatusParams } from './useKycStatus.types';
import useUser from './useUser';

const staleTime = 5 * MINUTE;

type DealerStatuses = (typeof DEALER_STATUS)[keyof typeof DEALER_STATUS];

const userAllowedStatuses = new Set<DealerStatuses>([DEALER_STATUS.APPROVED_PREVIEW]);
export const isKycInProgressAllowedStatuses = new Set<KycStatus>([KycStatus.notStarted, KycStatus.kycStarted]);
export const kycCompleteLiveOnProStatus = new Set<KycStatus>([KycStatus.completeLiveOnPro]);

export const kycCompleteStatuses = new Set<KycStatus>([
	KycStatus.kycCompleted,
	KycStatus.kycCompletedChangesRequested,
	KycStatus.modulrApproved,
	KycStatus.requestedToGoLive,
	KycStatus.sentToModulr,
	KycStatus.sentToModulrActionRequired,
	KycStatus.requestOtpOnPro,
]);
export const isKycCompletedAllowedStatuses = new Set<KycStatus>([
	...kycCompleteLiveOnProStatus,
	...kycCompleteStatuses,
]);

export const ALLOWED_KYC_STATUSES_FOR_OTP_MODAL = new Set<KycStatus>([
	...kycCompleteLiveOnProStatus,
	KycStatus.requestOtpOnPro,
	KycStatus.requestedToGoLive,
]);

export const useHandleKycStatus = (
	{ source, stateChangeSyncFn }: UseHandleKycStatusParams = {
		source: 'unknown',
	},
) => {
	const { kycStatus, lastFetchedAt, setState } = useKycStore((state) => state);

	let isActiveHookInstanceRefDefaultValue = false;

	if (!isSSR()) {
		if (!window.___kyc_status_fetch_lock) {
			window.___kyc_status_fetch_lock = source;
		}

		if (window.___kyc_status_fetch_lock === source) {
			isActiveHookInstanceRefDefaultValue = true;
		}
	}

	const isActiveHookInstanceRef = useRef<boolean>(isActiveHookInstanceRefDefaultValue);
	const isKycFFEnabled = useFeatureToggle(FEATURES.kyc);

	const user = useUser();

	const userStatus = user?.dealerStatus?.slug as DealerStatuses;
	const isEnabled = user && !user.isLoggedOut && isKycFFEnabled && kycStatus !== KycStatus.completeLiveOnPro;
	const isStale = Boolean(lastFetchedAt ? +new Date() - lastFetchedAt > staleTime : true);

	const stateChangeSyncFnConstant = useConstantCallback(stateChangeSyncFn || noop);

	const setKycStatus = useCallback(
		(nextKycStatus?: KycStatus) => {
			if (!isKycFFEnabled) {
				return;
			}

			setState({
				kycStatus: nextKycStatus,
				lastFetchedAt: +new Date(),
			});

			stateChangeSyncFnConstant({ kycStatus: nextKycStatus });
		},
		[isKycFFEnabled, stateChangeSyncFnConstant, setState],
	);

	const isDealerInKYCProcess = useCallback(() => {
		return Boolean(userAllowedStatuses.has(userStatus) && isKycFFEnabled && !!kycStatus);
	}, [isKycFFEnabled, kycStatus, userStatus]);

	const isKycInProgress = useCallback((): boolean => {
		return isDealerInKYCProcess() && isKycInProgressAllowedStatuses.has(kycStatus as KycStatus);
	}, [isDealerInKYCProcess, kycStatus]);

	const isKycCompleted = useCallback((): boolean => {
		return isDealerInKYCProcess() && isKycCompletedAllowedStatuses.has(kycStatus as KycStatus);
	}, [isDealerInKYCProcess, kycStatus]);

	useEffect(() => {
		const controller: AbortController = new AbortController();

		if (!isActiveHookInstanceRef.current) {
			return undefined;
		}

		if (!isEnabled) {
			return undefined;
		}

		if (!isStale) {
			return undefined;
		}

		const fetchData = async () => {
			try {
				const { kycStatus: nextKycStatus } = await getDataFromResponse(
					getKycStatus({ config: { signal: controller.signal } }),
				);

				setState({
					kycStatus: nextKycStatus,
					lastFetchedAt: +new Date(),
				});
			} catch (e) {
				const message = 'There was an issue with fetching kyc status';
				const error = e instanceof Error ? e : new Error(message);
				logger.error({ context: { extra: { info: message } }, error, message, scope: 'useHandleKycStatus' });
			}
		};

		void fetchData();

		return () => {
			controller.abort();
		};
	}, [isEnabled, isStale, setState, source]);

	useEffect(() => {
		return () => {
			if (!isSSR()) {
				if (window.___kyc_status_fetch_lock === source) {
					window.___kyc_status_fetch_lock = null;
				}
			}
		};
	}, [source]);

	return {
		isDealerInKYCProcess,
		isKycCompleted,
		isKycInProgress,
		isKycStatusLoading: !kycStatus && isKycFFEnabled,
		kycStatus,
		setKycStatus,
	};
};
