export const WIZARD_GROUP_ELEMENT = 'WizardGroup';

const WizardGroup = <WizardState extends UnknownShapeObject>({
	children,
}: {
	children: React.ReactNode;
	if?: (props: WizardState) => boolean;
}): React.ReactElement => <>{children}</>;

WizardGroup.displayName = WIZARD_GROUP_ELEMENT;

export default WizardGroup;
