import NextLink from 'next/link';

import { Button } from '@motorway/mw-highway-code';

import cypressIds from 'Components/cypress_ids.json';
import {
	triggerNavigationHowItWorksClickedEvent,
	triggerSignInLinkClicked,
	triggerSignUpLinkClicked,
} from 'Services/analytics/events';
import { Link, MenuItem, MenuList } from 'Storybook/core/Menu';
import type { NavMenuRouter } from 'Storybook/core/Menu/Menu.types';
import ProductMenuItem from 'Storybook/local/SiteNavigation/components/ProductMenuItem/ProductMenuItem';
import { triggerGuestSiteNavigationGAEvent } from 'Storybook/local/SiteNavigation/SiteNavigation.analytics';
import { Variant } from 'Types/variant';
import { applyCypressData } from 'Utilities/helpers';
import ROUTES from 'Utilities/routes';

import { MENU_ITEMS } from '../../SiteNavigation.consts';

import { howItWorksActiveStateCallback } from './GuestMenu.helpers';

import styles from './GuestMenu.module.scss';

type GuestMenuProps = {
	router: NavMenuRouter;
};

const GuestMenuButtons: React.FC<GuestMenuProps> = ({ router }) => (
	<>
		<ProductMenuItem router={router} side="right" triggerGAEvent={triggerGuestSiteNavigationGAEvent} />
		<MenuItem className={styles.actionButton}>
			<NextLink legacyBehavior passHref as={ROUTES.SIGN_IN.as()} href={ROUTES.SIGN_IN.href}>
				<Button
					{...applyCypressData(cypressIds.guestMenuLinks.signIn)}
					data-thc-button
					fullWidth
					as="a"
					label={MENU_ITEMS.SIGN_IN.LABEL}
					onClick={triggerSignInLinkClicked}
					variant={Variant.SECONDARY}
				/>
			</NextLink>
		</MenuItem>
		<MenuItem className={styles.actionButton}>
			<NextLink legacyBehavior passHref as={ROUTES.SIGN_UP.as()} href={ROUTES.SIGN_UP.href}>
				<Button
					{...applyCypressData(cypressIds.guestMenuLinks.signUp)}
					data-thc-button
					data-thc-style-override
					fullWidth
					as="a"
					label={MENU_ITEMS.SIGN_UP_NOW.LABEL}
					onClick={triggerSignUpLinkClicked}
					variant={Variant.PRIMARY}
				/>
			</NextLink>
		</MenuItem>
	</>
);

const GuestMenu: React.FC<GuestMenuProps> = ({ router }) => {
	return (
		<MenuList className={styles.menuList}>
			<MenuItem
				onClick={() => {
					triggerGuestSiteNavigationGAEvent('howItWorks');
					triggerNavigationHowItWorksClickedEvent();
				}}
			>
				<Link
					as={ROUTES.HOW_IT_WORKS.as()}
					href={ROUTES.HOW_IT_WORKS.href}
					isActive={howItWorksActiveStateCallback(router)}
					isListItem={false}
					text={MENU_ITEMS.HOW_IT_WORKS.LABEL}
					variant={Variant.SECONDARY}
					{...applyCypressData(cypressIds.guestMenuLinks.howItWorks)}
				/>
			</MenuItem>
			<GuestMenuButtons router={router} />
		</MenuList>
	);
};

export default GuestMenu;
