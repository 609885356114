import T, { MDText } from 'i18n-react';

import { toast } from '@motorway/mw-highway-code/pre-alpha';

import texts from 'Components/texts.json';
import axios from 'Services/axios';
import { logger } from 'Services/logger/logger';
import { openInNewTabOrForward } from 'Utilities/helpers';
import { API_ROUTES } from 'Utilities/routes';

import siteNavigationTexts from './billing.texts';
import type { GetInvoicedMagicLinkResponse, HandleBillingLinkProps } from './billing.types';

const LocalT = new MDText(siteNavigationTexts);

T.setTexts(texts);

export const handleBillingLink = async ({ dealerId, getInvoicedMagicLink }: HandleBillingLinkProps): Promise<void> => {
	const data: GetInvoicedMagicLinkResponse = await getInvoicedMagicLink(dealerId);
	const magicLoginLink = data?.magicLoginLink;

	if (magicLoginLink) {
		openInNewTabOrForward(magicLoginLink);
	} else {
		toast({
			content: LocalT.translate('errorMessage'),
			variant: 'error',
		});

		const errorMessage = 'Magic link not found';
		const cause = 'Magic link not found in response data';
		const error = new Error(errorMessage, { cause });

		logger.error({
			context: {
				cause,
				extra: {
					dealerId,
					magicLoginLink,
				},
				fingerprint: [errorMessage],
			},
			error,
			message: errorMessage,
			scope: 'handleBillingLink',
		});
	}
};

export const getInvoicedMagicLink = async (dealerId: number): Promise<Nullable<GetInvoicedMagicLinkResponse>> => {
	try {
		const url = API_ROUTES.INVOICED_MAGIC_LINK.replace(':dealerId', dealerId.toString());
		const { data } = await axios.get(url);
		return data;
	} catch (e) {
		const info = 'Could not fetch invoiced magic link';
		const error = e instanceof Error ? e : new Error(info);
		const context = { extra: { info } };

		logger.error({ context, error, message: error.message, scope: 'getInvoicedMagicLink' });

		return null;
	}
};
