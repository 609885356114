import React from 'react';
import cx from 'classnames';
import Link from 'next/link';

import { AccordionContent } from 'Storybook/peripheral/Accordion';
import { HorizontalAlignment } from 'Types/generic';
import { applyCypressData } from 'Utilities/helpers';

import type { NavAccordionList, NavAccordionRouter } from '../../NavAccordion.types';

import styles from './NavAccordionContentList.module.scss';

// NOTE: Var used for animation. If list children height changes, it should be updated.
const SUBITEM_HEIGHT = 56;

export type NavAccordionContentListProps = {
	router: NavAccordionRouter;
	subItems: NavAccordionList['subItems'];
};

const NavAccordionContentList: React.FC<NavAccordionContentListProps> = ({ router, subItems }) => {
	return (
		<AccordionContent
			style={
				{
					'--maxHeight': `${(subItems?.length || 1) * SUBITEM_HEIGHT}px`,
				} as React.CSSProperties
			}
		>
			{subItems.map(
				({
					as,
					cypressId,
					extensionContent: ExtensionContent,
					extensionPosition = HorizontalAlignment.Right,
					getActiveState,
					hide,
					href,
					name,
					onClick,
				}) =>
					!hide ? (
						<Link
							key={as}
							aria-current={getActiveState(router)}
							as={as}
							className={cx(styles.subItem, {
								[styles.isActive]: getActiveState(router),
							})}
							href={href}
							onClick={onClick}
							onKeyDown={onClick}
							{...applyCypressData(cypressId)}
						>
							{name}
							{ExtensionContent && (
								<div
									className={cx(styles.extensionPosition, {
										[styles.extensionPositionRight]: extensionPosition === HorizontalAlignment.Right,
										[styles.extensionPositionLeft]: extensionPosition === HorizontalAlignment.Left,
									})}
								>
									<ExtensionContent />
								</div>
							)}
						</Link>
					) : null,
			)}
		</AccordionContent>
	);
};

export default NavAccordionContentList;
