import React, { useCallback, useState } from 'react';

import MFAWrapper from 'Components/MFAWrapper/MFAWrapper';
import { isUserOTPAuthorised } from 'Services/api/payment';
import { logger } from 'Services/logger/logger';
import { PAYMENT_MFA_ELEMENT_ID } from 'Utilities/consts';
import usePaymentsMVPEnabled from 'Utilities/hooks/usePaymentsMVPEnabled';
import useUser from 'Utilities/hooks/useUser';

import { getInvoicedMagicLink, handleBillingLink } from './billing.helpers';

export const useBilling = (props?: { allowViewEscape: boolean }) => {
	const { allowViewEscape = true } = props || {};

	const [isPortalVisible, setIsPortalVisible] = useState<boolean>(false);

	const { dealerId, hasInvoiceAccess: isBillingEnabled } = useUser();

	const isPaymentsMVPEnabled = usePaymentsMVPEnabled();

	const hideViewElement = useCallback(() => {
		setIsPortalVisible(false);
	}, []);

	const showViewElement = useCallback(() => {
		setIsPortalVisible(true);
	}, []);

	const onSuccess = useCallback(() => {
		handleBillingLink({ dealerId, getInvoicedMagicLink }).catch((error) => {
			logger.warn({
				context: { error },
				message: error?.message || 'Error handling billing link',
				scope: 'useBilling',
			});
		});

		hideViewElement();
	}, [dealerId, hideViewElement]);

	const onBillingClick = useCallback(async () => {
		try {
			if (isPaymentsMVPEnabled) {
				// Dealers in Payments have to OTP authenticate before accessing billing
				await isUserOTPAuthorised();
			}

			onSuccess();
		} catch {
			showViewElement();
		}
	}, [isPaymentsMVPEnabled, showViewElement, onSuccess]);

	const viewElement = isPortalVisible ? (
		<MFAWrapper
			fullScreen
			logs={{
				origin: 'otp_setup_billing',
				source: 'accessSensitiveData',
			}}
			onBackClick={allowViewEscape ? hideViewElement : undefined}
			onSuccess={onSuccess}
			portalId={PAYMENT_MFA_ELEMENT_ID}
		/>
	) : null;

	return {
		hideViewElement,
		isBillingEnabled,
		isPortalVisible,
		onBillingClick,
		showViewElement,
		viewElement,
	};
};
